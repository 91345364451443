import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from 'src/services/apiservice.service';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { createResponse } from 'src/models/checklist';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { customizing } from '../../models/customizing';
import { DashboardComponent } from 'src/usermodule/dashboard/dashboard.component';
import { MatOption } from '@angular/material/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/services/dataservice.service';
declare var $: any;
@Component({
  selector: 'app-checklist-modal',
  templateUrl: './checklist-modal.component.html',
  styleUrls: ['./checklist-modal.component.scss'],
  providers: [NgbModal],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistModalComponent implements OnInit {
  @ViewChild('content') private content: TemplateRef<Object>;
  @ViewChild('instructions') public instructionspopup: TemplateRef<Object>;
  @ViewChild('checklist') public checklistpopup: TemplateRef<Object>;
  @ViewChild('suncircle') public suncircle: TemplateRef<Object>;
  @ViewChild('compactchecklist') public compactchecklist: TemplateRef<Object>;
  @ViewChild('checklisttop') public checklisttop: TemplateRef<Object>;
  @ViewChild('onlyChecklist') public onlyChecklist: TemplateRef<Object>;
  @ViewChild('checklistinstructions') public checklistinstructions: TemplateRef<Object>;
  @ViewChild('extrainputmodal') public extrainputpopup: TemplateRef<Object>;
  @ViewChild('instructionanddocuments') public instructionanddocuments: TemplateRef<Object>;
  @ViewChild('topinstructionchecklist') public topinstructionchecklist: TemplateRef<Object>;
  @Input() checkListFormGroup: any;
  @Input() checkListFormGroup1: any;
  @Input() checkListItems: any;
  @Input() checkListItems1: any;
  //@Input() public goodsReciptFlag: any;
  selectedOption: string;
  instructionsItems: any;
  payload: { processStep: string; orderID: string; userID: string; };
  @Input() orderID: string;
  @Input() attachments: any;
  customizing: any;
  deviceInfo: import('ngx-device-detector').DeviceInfo;
  @Input() public goodsRecipt: any;
  @Input() public requiresValidationOnSubmit: boolean;
  isD = true;
  src: any;
  productForm: any;
  currentuser: any;
  modalref: NgbModalRef;
  showChecklist: any;
  stepFlag: string;
  extraInputForm: any;
  showInstructions: boolean;
  isLoading: boolean = true;
  // deviceInfo: import('ngx-device-detector').DeviceInfo;
  isKeyboard: boolean = false;
  allSelected = false;

  currentlang: any;
  errorMsg = false;
  isShow = true;
  isShoww = true;
  secondOrderId = '';
  workstationid: any;
  @Input() checklistFormList: any;
  @Input() check_List: any;
  pdfFlag: boolean;
  orderIDD: any;
  showInput = false;
  diactivatedFlag = false;
  diactivatedFlagfromdash = false;
  public checkfields: any;
  public getPostValues: any;
  public defaultValue: any;
  public layoutValues = [];
  public forceVirtualKeyboardEnabled = true;
  constructor(
    private cd: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private sanitizer: DomSanitizer,
    private DashboardComponent: DashboardComponent,
    public fb: UntypedFormBuilder,
    private apiservice: ApiService,
    private DataService: DataService,
    private toastrService: ToastrService,
    private modalService: NgbModal) {
    this.workstationid = localStorage.getItem('workstationID');
    this.currentuser = localStorage.getItem('current_user');
    (this.currentuser) ? this.currentuser = JSON.parse(this.currentuser) : '';
    this.productForm = this.fb.group({
      quantity: [1, [Validators.required]],
      types: [],
      default_quantity: [1,]
    });
    this.extraInputForm = this.fb.group({
      quantity: [1, [Validators.required]],
    });
    this.currentlang = localStorage.getItem('language');
  }
  checklistFormGroup: FormGroup<any>;
  locatieForm = new FormGroup({});
  inputText: string = '';
  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent): void {
    if (!this.diactivatedFlag && this.diactivatedFlagfromdash) {
      if (/^[a-zA-Z0-9]$/.test(event.key)) {
        this.inputText += event.key.toLowerCase();
        if (this.inputText.startsWith('http') || this.inputText.startsWith('https')) {
          this.showInput = true;
          $(document).ready(function (ex: any) {
            $('#inputvalue').focus();
          })
          event.stopPropagation();
          event.preventDefault();
        }
        else {
          this.showInput = false;
          // this.inputText = '';
        }
      }
      else if (event.key === 'Enter') {
        const urlPattern = /^https?:\/\/[^\s/$.?#].[^\s]*$/i;
        if (urlPattern.test(this.inputText)) {
          window.open(this.inputText, '_blank');
          this.inputText = '';
          this.showInput = false;
        }
        else {
          this.inputText = '';
          this.showInput = false;
        }
      }
    }

  }

  ngOnChanges() {
    if (this.checkListItems?.length == 0 || this.checkListItems == undefined) {
      this.checkListItems = this.checkListItems1
    }
    else if (!this.checkListFormGroup?.value?.details) {
      this.checkListFormGroup = this.checkListFormGroup1;
    }
  }
  ngOnInit(): void {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.deviceInfo.deviceType === 'mobile') {
      this.isKeyboard = true
    }
  }
  ngDoCheck() {
    if (this.checkListItems?.length == 0 || this.checkListItems == undefined) {

      this.checkListItems = this.checkListItems1
    }
    else if (!this.checkListFormGroup?.value?.details) {
      this.checkListFormGroup = this.checkListFormGroup1;
    }
    if (this.attachments?.length > 0 || this.attachments) {
      this.isShow = false;
    }
    // console.log(this.checkListFormGroup, 'checkListFormGroup..iio')
    // console.log(this.checkListItems, 'checkListItems..hu')
  }

  hideForUrl() {
    this.inputText = '';
    this.showInput = false;
  }
  toSelectUrl(value: any) {
    this.diactivatedFlag = true;
    this.showInput = false;
  }
  loadingPDF() {
    this.isLoading = false;
    this.cd.detectChanges();
  }

  valueChange(item: any, prop: any) {
    this.checkListFormGroup.patchValue({
      [prop.fieldName]: item
    });
    this.checkListItems.find((element: any) => {
      if (element.fieldName == prop.fieldName) {
        element.value = item;
      }
    });
  }

  resetForm() {
    // this.modalService.dismissAll();
    // this.checkListFormGroup.reset({});
  }
  addCompact(submitButton: HTMLButtonElement) {
    submitButton.disabled = true;
    let obj = []
    for (let item of this.checkListItems) {
      for (let prop of item.body) {
        for (let list of prop) {
          if (this.checkListFormGroup.value[list?.fieldName] != undefined) {
            let temp = this.checkListFormGroup.value[list?.fieldName];
            list.value = temp;
            obj.push(list)
          }
        }
      }
      for (let list of item.header) {
        if (this.checkListFormGroup.value[list?.fieldName] != undefined) {
          let temp = this.checkListFormGroup.value[list?.fieldName];
          list.value = temp;
          obj.push(list)
        }
      }
    }
    let item = this.checkListFormGroup.value.details;
    let payload = {
      ...item,
      "values": obj
    };
    this.apiservice.createCheckList(payload).subscribe(
      (res: createResponse) => {
        if (this.requiresValidationOnSubmit) {
          if (res.response === 'success') {
            this.modalService.dismissAll();
            this.DashboardComponent.openCloseInstructionModal('2', '', '', '', this.pdfFlag, false, false);
            this.orderIDD = item.orderID
            setTimeout(() => {
              this.postPrintDetails('1', false);
            }, 200);
          }
          else if (res.response === 'validationFailed') {
            this.toastrService.error(res.errorMessage, 'Error!');
          }
          else {

          }
        }
        else {
          this.modalService.dismissAll();
          this.DashboardComponent.openCloseInstructionModal('2', '', '', '', this.pdfFlag, false, false);
          this.orderIDD = item.orderID
          setTimeout(() => {
            this.postPrintDetails('1', false);
          }, 200);
        }

      },
      (error: any) => {
        console.log(error)
      }
    );
  }
  addChecklist(pitem: any, submitButton: HTMLButtonElement) {
    submitButton.disabled = true
    var option: any = {}
    let checkingTextfield = false;
    let checkfield;
    for (let i = 0; i < this.checkListItems?.length; i++) {
      if (this.checkListFormGroup.value[this.checkListItems[i]?.fieldName] != undefined) {
        let splittedDate;
        if (this.checkListItems[i].fieldType == 'date') {
          let date = this.checkListFormGroup.value[this.checkListItems[i]?.fieldName];
          let year = new Date(date).getFullYear();
          let month = new Date(date).getMonth() + 1;
          let day = new Date(date).getDate();
          splittedDate = day + '-' + month + '-' + year;
          this.checkListItems[i].value = splittedDate;
        }
        else if (this.checkListItems[i].fieldType == 'checkbox') {
          let temp = (this.checkListFormGroup.value[this.checkListItems[i]?.fieldName] == true || this.checkListFormGroup.value[this.checkListItems[i]?.fieldName] == '1' || this.checkListFormGroup.value[this.checkListItems[i]?.fieldName] == true) ? true : false;
          this.checkListItems[i].value = temp;
        }
        else if (this.checkListItems[i].fieldType === 'select') {

          let temp = this.checkListFormGroup.value[this.checkListItems[i]?.fieldName];
          this.checkListItems[i].value = temp;
          // let tempp=this.checkListItems[i]['multi-select']?temp:[temp]
          //  let kl:any=[]
          // this.checkListItems[i].options.forEach((v:any,l:any)=>{
          //   tempp.forEach((key:any)=>{
          //     if(v.id === key){
          //       kl.push(v)
          //     }
          //     else{
          //     }
          //   })
          //  })
          //  option[i]={
          //   options:kl
          //  }
        }
        else if (this.checkListItems[i].fieldType === 'text' && this.checkListItems[i].allowedValues && this.checkListItems[i].allowedValues.length > 0) {

          if (this.checkListItems[i].allowedValues) {
            checkingTextfield = true
            let temp = this.checkListFormGroup.value[this.checkListItems[i]?.fieldName];
            checkfield = this.checkListItems[i].allowedValues.filter((v: any) => v === temp)
            this.checkListItems[i].value = temp;
          }
        }
        else {
          this.checkListItems[i].value = this.checkListFormGroup.value[this.checkListItems[i]?.fieldName]
        }
      }
      let activeorder = JSON.parse(localStorage.getItem("active_order") || '{}');
      if (this.checkListFormGroup.value.details) {
        // this.checkListFormGroup.value.details.orderID = this.secondOrderId != '' ? this.DashboardComponent.secondOrderId : activeorder.orderID != '' ? activeorder.orderID : this.checkListFormGroup.value.details.orderID;
      }
    }
    this.checkfields = checkfield
    if (checkingTextfield) {
      if (checkfield.length > 0) {

        let item = this.checkListFormGroup.value.details;
        let payload = {
          ...item,
          "values": this.checkListItems
        };
        this.checkListFormGroup.reset({});
        this.modalService.dismissAll();
        this.apiservice.createCheckList(payload).subscribe(
          (res: createResponse) => {
            this.DashboardComponent.modaldismiss = true;
            this.DashboardComponent.openCloseInstructionModal('2', '', '', '', this.pdfFlag, false, false);
            this.orderIDD = item.orderID
            setTimeout(() => {
              this.postPrintDetails('1', false);
            }, 200);
          },
          (error: any) => {
            console.log(error)
          }
        );

      }
      else {

        let currentlang = JSON.parse(localStorage.getItem('cuurenLanguage') || '{}');
        (Object.keys(currentlang).length) ? this.currentlang = currentlang.name : this.currentlang = 'English'
        let errormsg;
        this.errorMsg = true;
        submitButton.disabled = false;
        if (this.currentlang === 'Dutch') {
          errormsg = 'Opgegeven waarde niet toegestaan'
        }
        else if (this.currentlang === 'English') {
          errormsg = 'Value provided is not allowed'
        }
        else {
        }
        this.toastrService.error(errormsg, 'Error!');
      }
    } else {


      let item = this.checkListFormGroup.value.details;
      let payload = {
        ...item,
        "values": this.checkListItems
      };
      // this.checkListFormGroup.reset({});
      if (pitem == 'small') {
        this.modalService.dismissAll();
      }
      this.apiservice.createCheckList(payload).subscribe(
        (res: createResponse) => {
          this.orderIDD = item?.orderID
          this.DashboardComponent.modaldismiss = true;
          this.DashboardComponent.openCloseInstructionModal('2', '', '', '', this.pdfFlag, false, false);
          setTimeout(() => {
            this.postPrintDetails('1', false);
          }, 200);

        },
        (error: any) => {
          console.log(error)
        }
      );

    }
  }
  aaddChecklist(pitem: any, submitButton: HTMLButtonElement) {
    submitButton.disabled = true
    var option: any = {}
    let checkingTextfield = false;
    let checkfield;
    for (let i = 0; i < this.check_List?.length; i++) {
      if (this.checklistFormList.value[this.check_List[i]?.fieldName] != undefined) {
        let splittedDate;
        if (this.check_List[i].fieldType == 'date') {
          let date = this.checklistFormList.value[this.check_List[i]?.fieldName];
          let year = new Date(date).getFullYear();
          let month = new Date(date).getMonth() + 1;
          let day = new Date(date).getDate();
          splittedDate = day + '-' + month + '-' + year;
          this.check_List[i].value = splittedDate;
        }
        else if (this.check_List[i].fieldType == 'checkbox') {
          let temp = (this.checklistFormList.value[this.check_List[i]?.fieldName] == true || this.checklistFormList.value[this.check_List[i]?.fieldName] == '1' || this.checklistFormList.value[this.check_List[i]?.fieldName] == true) ? true : false;
          this.check_List[i].value = temp;
        }
        else if (this.check_List[i].fieldType === 'select') {

          let temp = this.checklistFormList.value[this.check_List[i]?.fieldName];
          this.check_List[i].value = temp;
          // let tempp=this.check_List[i]['multi-select']?temp:[temp]
          //  let kl:any=[]
          // this.check_List[i].options.forEach((v:any,l:any)=>{
          //   tempp.forEach((key:any)=>{
          //     if(v.id === key){
          //       kl.push(v)
          //     }
          //     else{
          //     }
          //   })
          //  })
          //  option[i]={
          //   options:kl
          //  }
        }
        else if (this.check_List[i].fieldType === 'text' && this.check_List[i].allowedValues && this.check_List[i].allowedValues.length > 0) {

          if (this.check_List[i].allowedValues) {
            checkingTextfield = true
            let temp = this.checklistFormList.value[this.check_List[i]?.fieldName];
            checkfield = this.check_List[i].allowedValues.filter((v: any) => v === temp)
            this.check_List[i].value = temp;
          }
        }
        else {
          this.check_List[i].value = this.checklistFormList.value[this.check_List[i]?.fieldName]
        }
      }
      let activeorder = JSON.parse(localStorage.getItem("active_order") || '{}');
      if (this.checklistFormList.value.details) {
        // this.checklistFormList.value.details.orderID = this.secondOrderId != '' ? this.DashboardComponent.secondOrderId : activeorder.orderID != '' ? activeorder.orderID : this.checklistFormList.value.details.orderID;
      }
    }
    if (checkingTextfield) {
      if (checkfield.length > 0) {

        let item = this.checklistFormList.value.details;
        let payload = {
          ...item,
          "values": this.check_List
        };
        this.checklistFormList.reset({});
        this.modalService.dismissAll();
        this.apiservice.createCheckList(payload).subscribe(
          (res: createResponse) => {
            this.DashboardComponent.modaldismiss = true;
            this.orderIDD = item.orderID
            if (this.DashboardComponent.formgroupss.length > 1) {
              this.DashboardComponent.openCloseInstructionModal('2', '', '', '', this.pdfFlag, false, false);
            }
            setTimeout(() => {
              this.postPrintDetails('1', false);
            }, 200);
          },
          (error: any) => {
            console.log(error)
          }
        );
      }
      else {

        let currentlang = JSON.parse(localStorage.getItem('cuurenLanguage') || '{}');
        (Object.keys(currentlang).length) ? this.currentlang = currentlang.name : this.currentlang = 'English'
        let errormsg;
        this.errorMsg = true;
        submitButton.disabled = false;
        if (this.currentlang === 'Dutch') {
          errormsg = 'Opgegeven waarde niet toegestaan'
        }

        else if (this.currentlang === 'English') {
          errormsg = 'Value provided is not allowed'
        }
        else {
        }
        this.toastrService.error(errormsg, 'Error!');
      }
    } else {


      let item = this.checklistFormList.value.details;
      let payload = {
        ...item,
        "values": this.check_List
      };
      // this.checklistFormList.reset({});
      if (pitem == 'small') {
        this.modalService.dismissAll();
      }
      this.apiservice.createCheckList(payload).subscribe(
        (res: createResponse) => {
          this.DashboardComponent.modaldismiss = true;
          this.orderIDD = item.orderID
          if (this.DashboardComponent.formgroupss.length > 1) {
            this.DashboardComponent.openCloseInstructionModal('2', '', '', '', this.pdfFlag, false, false);
          } setTimeout(() => {
            this.postPrintDetails('1', false);
          }, 200);

        },
        (error: any) => {
          console.log(error)
        }
      );

    }
  }



  setOption(flag: string, data: any) {
    // this.secondOrderId = '';
    this.selectedOption = flag;
    let active_order = JSON.parse(localStorage.getItem("active_order") || '{}');
    let mapping_field = active_order?.mapping_field ? active_order?.mapping_field : ''
    if (flag === 'instructions') {
      this.apiservice.getInstructions(this.orderID, mapping_field).subscribe(
        (res: any) => {
          if (res?.response == 'success') {
            this.instructionsItems = res?.fields ? res?.fields : '';
            this.layoutValues = res.layout
          }
        }, (error: any) => {
          console.log(error);
        }
      )
    }
    else if (flag != 'instructions' && flag != 'checklist') {
      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.isLoading = true;

      if (this.deviceInfo.deviceType != 'mobile') {
        this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
          environment.fileurl +
          data.filename +
          '#toolbar=0&navpanes=0&scrollbar=0'
        );
      } else {
        $(".loader").css({
          display: 'block'
        });
        // this.src =
        //   environment.fileurl +
        //   data.filename +
        //   '#toolbar=0&navpanes=0&scrollbar=0'
        //   ;
        // window.open(environment.fileurl +
        //   data.filename +
        //   '#toolbar=0&navpanes=0&scrollbar=0');
        let originalUrl = data.filename;
        let encodedUrl = encodeURIComponent(originalUrl);
        // this.src = this.sanitizer.bypassSecurityTrustResourceUrl('https://docs.google.com/gview?embedded=true&url=' + environment.fileurl + encodedUrl + '#toolbar=0&navpanes=0&scrollbar=0')
        $(document).on("click", function (e: any) {
          $(".error-message").css({
            display: 'none'
          })
          $(".iframeTou").css({ display: 'block' })
          let originalUrl = data.filename;
          let errorMessage = false;
          let encodedUrl = encodeURIComponent(originalUrl);
          let loading: any;
          if (loading) {
            clearInterval(loading);
          }
          const timestamp = new Date().getTime();
          var newSrc = 'https://docs.google.com/gview?embedded=true&url=' + environment.fileurl + encodedUrl + `&cachebuster=${timestamp}`;
          $(".iframeTou").attr('src', newSrc);
          $(".iframeTou").on('load', function () {
            errorMessage = true;
            $(".loader").css({
              display: 'none'
            });
          })
          loading = setInterval(() => {
            if (!errorMessage) {
              $(".iframeTou").attr('src', newSrc);
              $(".iframeTou").on('load', function () {
                errorMessage = true;
                $(".loader").css({
                  display: 'none'
                });
                if (loading) {
                  clearInterval(loading);
                }
              });
            }
          }, 5000);
        });
      }
    }
  }

  getClass(value: any) {
    return `col-md-${value}`;
  }
  async localHosthandler(item: any) {
    return new Promise((resolve) => {
      setTimeout(() => {
        let obj = {
          labeldata: item.labeldata,
          copies: item.quantity
        }
        this.apiservice.localhostPost(obj).toPromise();
        resolve('success');
      }, 200);
    });
  }

  public parentResult: any = [];
  async postPrintDetails(flag: string, autoprint: boolean) {
    let current_user: any = JSON.parse(localStorage.getItem('current_user') || '');
    let askQuantity;
    let autoPrint;
    let processStep;
    let printOptions = false;
    let order: any = localStorage.getItem("active_order");
    let activeorder = JSON.parse(localStorage.getItem("active_order") || '{}');
    let orderIdd: any;
    orderIdd = this.secondOrderId != '' ? this.DashboardComponent.secondOrderId : activeorder.orderID != '' ? activeorder.orderID : this.orderIDD;
    (order) ? order = JSON.parse(order) : "";
    if (this.customizing?.triggerPrint != null) {
      this.customizing?.triggerPrint?.forEach((element: any) => {
        autoPrint = element.autoprint
        if (element.processStep == 'afterChecklistComplete') {
          printOptions = true;
          processStep = element.processStep;
        }
        askQuantity = element.askQuantity;
      });

      if (flag == '1' || (flag == '0' && askQuantity != true && printOptions)) {
        if (autoPrint || autoprint) {
          let obj: any = {}
          for (let i = 0; i < this.parentResult?.length; i++) {
            let print = this.parentResult[i].id
            let formValues = this.productForm.value;
            if (print in formValues) {
              obj[print] = this.productForm.value[print]
            }
          }
          let payload = {
            ...obj,
            "orderID": orderIdd,
            "quantity": (!this.modalref) ? null : this.productForm.value?.quantity?.toString(),
            "userID": current_user?.userID,
            "orderkey": activeorder.mapping_field ? activeorder.mapping_field : '',
            "processStep": this.productForm.value?.types ? this.productForm.value.types : processStep,
          };
          (this.modalref) ? this.modalref.close() : '';
          this.apiservice.addQuantity(payload).subscribe(
            async (res: any) => {
              if (res?.response == 'success') {
                if (res?.printbody != null) {
                  for (let i in res.printbody) {
                    await this.localHosthandler(res.printbody[i]);
                  }
                }
                this.productForm.reset({});
                this.secondOrderId = '';
                this.modalref;
              }
            },
            (error: any) => {
              console.log(error)
            }
          );
        }
      } else {
        let orderkey = activeorder.mapping_field ? activeorder.mapping_field : '';
        this.apiservice.aaddQuantity(orderIdd, orderkey, current_user?.userID, this.workstationid).subscribe(
          async (res: any) => {
            this.getPostValues = res;
            this.defaultValue = res.types[0]?.processStep;
            this.onChangeLabel(this.defaultValue)
            console.log(this.parentResult)
            this.productForm.get('quantity').setValue(res.default_quantity)
          })

        if (askQuantity == true) {
          this.modalref = this.modalService.open(this.content, { modalDialogClass: 'orderline' });
        }
      }

    }
    else {
      this.printOptinal(1)
    }
  }


  async printOptinal(flag: any) {
    let current_user: any = JSON.parse(localStorage.getItem('current_user') || '');
    let askQuantity;
    let autoPrint;
    let processStep;
    let printOptions = false;
    let order: any = localStorage.getItem("active_order");
    let activeorder = JSON.parse(localStorage.getItem("active_order") || '{}');
    let orderIdd: any;
    orderIdd = this.secondOrderId != '' ? this.DashboardComponent.secondOrderId : activeorder.orderID != '' ? activeorder.orderID : this.orderIDD;
    if (flag == '1') {
      let payload = {
        "orderID": orderIdd,
        "quantity": (!this.modalref) ? null : this.productForm.value?.quantity?.toString(),
        "userID": current_user?.userID,
        "orderkey": activeorder.mapping_field ? activeorder.mapping_field : '',
        "processStep": this.productForm.value?.types ? this.productForm.value.types : processStep,
      };
      (this.modalref) ? this.modalref.close() : '';
      this.apiservice.addQuantity(payload).subscribe(
        async (res: any) => {
          if (res?.response == 'success') {
            if (res?.printbody != null) {
              for (let i in res.printbody) {
                await this.localHosthandler(res.printbody[i]);
              }
            }
            this.productForm.reset({});
            this.secondOrderId = '';
            this.modalref;
          }
        },
        (error: any) => {
          console.log(error)
        }
      );

    } else {
      let orderkey = activeorder.mapping_field ? activeorder.mapping_field : '';
      this.apiservice.aaddQuantity(orderIdd, orderkey, current_user?.userID, this.workstationid).subscribe(
        async (res: any) => {
          this.getPostValues = res;
          this.defaultValue = res.types[0].processStep;
          this.productForm.get('quantity').setValue(res.default_quantity);
          for (let list of this.getPostValues?.types) {
            this.productForm.addControl(list.description, new FormControl(''));
          }
        })

      this.modalref = this.modalService.open(this.content, { modalDialogClass: 'orderline' });

    }
  }

  public onChangeLabel(event: any): void {
    let pr = event;
    let parentResult: any = []
    this.getPostValues?.types?.forEach((v: any) => {
      v?.inputfields?.forEach((k: any) => {
        k['parent'] = v.processStep
        parentResult.push(k)
      })
    })
    this.parentResult = parentResult.filter((v: any) => v.parent === pr);
    for (let i = 0; i < this.parentResult.length; i++) {
      let print = this.parentResult[i].id
      let formValues = this.productForm.value;
      if (print in formValues) {
        this.productForm.patchValue({ [print]: this.parentResult[i].default });
      } else {
        let values = this.parentResult[i].default ? this.parentResult[i].default : ''
        this.productForm.addControl(print, new FormControl(values));
      }
    }

  }
  openExtraInputModal(modal: any) {
    this.modalService.open(modal, { size: 'md' });
  }

  activities() {
    this.apiservice.getgreenactivities().subscribe(
      (res: customizing) => {
        if (res.response) {
          this.customizing = res.response;
        } else {
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  openInstructionList(instructions: any, payload: any, checklistFlag: any, stepFlag: string, attachmentFiles: any, dialogType: string, secondOrderId: any, pdfFlag: boolean, instructionanddoc: boolean) {
    this.customizing = JSON.parse(localStorage.getItem('customizing') || '{}')
    this.pdfFlag = pdfFlag;
    this.secondOrderId = secondOrderId
    this.isShow = true;
    this.stepFlag = stepFlag;
    this.showChecklist = false;
    this.showInstructions = false;
    if (payload && payload?.processStep != '') {
      if (checklistFlag == true) {
        this.showChecklist = checklistFlag;
        this.selectedOption = 'checklist';
      } else if (this.customizing?.showInstructions == stepFlag) {
        this.showInstructions = true;
        this.setOption('instructions', '');
      } else {
        this.showChecklist = true;
        this.selectedOption = 'checklist';
        this.setOption('checklist', '');
        if (instructionanddoc) {
          setTimeout(() => {
            this.setOption('instructions', '');
          }, 500)
        }


      }
    } else {
      if (this.customizing.showInstructions != null) {
        this.showInstructions = true;
        this.setOption('instructions', '');
      }
      else if (instructionanddoc) {
        this.showInstructions = true;
        this.setOption('instructions', '');
      }
      else {
        (attachmentFiles?.length > 0) ?
          this.setOption(attachmentFiles[0]?.document_type, attachmentFiles[0]) : '';
      }
    }
    if (dialogType === 'small') {
      let closeResult
      this.modalService.open(this.extrainputpopup, { size: 'md' }).result.then((result) => {
        closeResult = `Closed with: ${result}`;
        this.errorMsg = false
      }, (reason) => {
        this.errorMsg = false;
        closeResult = `Dismissed ${this.getDismissReason(reason)
          }`;
      });;
      setTimeout(() => {
        let item;
        (this.checkListItems?.length > 0) ? item = this.checkListItems[0] : item = '';
        const projectInput: any = document.querySelector(`input[name='${item.fieldName}'` + ']');
        projectInput.focus();
      }, 500);
    } else {
      // let checklistFlagg = checklistFlag = true ? 'checklist' : 'instructor'
      let closeResult;
      this.modalService.open(instructions, { modalDialogClass: `instructions ${checklistFlag} ` }).result.then((result) => {
        closeResult = `Closed with: ${result}`;
        this.errorMsg = false
      }, (reason) => {
        this.errorMsg = false;
        this.DashboardComponent.hideInstructionpopup = false;
        closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.showInput = false;
        this.inputText = ''
        this.diactivatedFlag = false;
        this.diactivatedFlagfromdash = false;
        if (closeResult !== 'Dismissed with: Cross clicked') {
          if (this.DashboardComponent.triggerChecklist?.length <= 1) {
            // this.DashboardComponent.checkListItems = [];
            // this.DashboardComponent.checkListFormGroup?.reset({});
            // this.DashboardComponent.checkListFormGroup1?.reset({});
            // this.DashboardComponent.checkListItems1 = [];
          }
        }
      });;;
      const modal: any = document.querySelector("ngb-modal-window");
      modal.style.overflow = "hidden";
      // const modal1: any = document.querySelector("false");
      // modal1.style.visibility = 'hidden !important'
      // modal.style['z-index'] = '0 !important';
    }
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public openLocatie(modal: any): void {
    this.locatieForm.reset()
    console.log(this.instructionsItems)
    if (this.instructionsItems?.canModify) {
      for (let i = 0; i < this.instructionsItems.canModify.length; i++) {
        let key = this.instructionsItems.canModify[i]['name'];
        console.log(key);
        this.locatieForm.addControl(key, new FormControl(''));
      }
    }
    this.modalref = this.modalService.open(modal, { modalDialogClass: 'locatie-wrapper-dailog' });
  }

  forceVirtualKeyboard() {
    if (!this.forceVirtualKeyboardEnabled) return;
    const input = document.createElement('input');
    input.style.position = 'absolute';
    input.style.opacity = '0';
    input.style.height = '0';
    input.style.width = '0';
    input.style.zIndex = '-1';
    document.body.appendChild(input);
    input.focus();
    setTimeout(() => {
      input.blur();
      document.body.removeChild(input);
    }, 100);
  }
  public locatieSubmit(): void {
    let p: any = this.locatieForm.value
    let obj = Object.keys(p).map(k => ({
      name: k,
      value: p[k]
    }))
    let activeorder = JSON.parse(localStorage.getItem("active_order") || '{}');
    let payload = {
      orderID: activeorder.orderID,
      orderkey: activeorder.mapping_field ? activeorder.mapping_field : '',
      field: obj
    }
    this.apiservice.postInstructions(payload).subscribe(res => {
      console.log(res)
      this.modalref.close()
    })
  }
}
