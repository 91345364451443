<ng-template #loading let-modal>
  <div class="spinner-border d-flex align-self-center" style="width: 3rem; height: 3rem;" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</ng-template>

<nav class="navbar navbar-primary static-top">
  <div class="container-fluid">
    <span class="text-white" id="userName">{{ name }}</span>
    <nav class="d-flex flex-row flex-nowrap">
      <ul class="nav nav-pills nav-lang" *ngIf="this.customerID === '6'">
        <li class="nav-item dropdown d-flex align-items-center">
          <a class="nav-link dropdown-toggle text-white" data-bs-toggle="dropdown" role="button"
            aria-expanded="false" (click)="getPrintersForWorkstation()"> <i class="bi bi-printer d-inline-block"></i></a>
          <ul class="dropdown-menu">
            <li *ngFor="let item of printers">
              <a class="dropdown-item" (click)="setPrinterForWorkstation(item)">{{
                item
                }} </a>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="nav nav-pills nav-lang">
        <li class="nav-item dropdown d-flex align-items-center">
          <i class="icon-global d-inline-block"></i>
          <a class="nav-link dropdown-toggle text-white" data-bs-toggle="dropdown" role="button"
            aria-expanded="false">{{ currentlang }}</a>
          <ul class="dropdown-menu">
            <li *ngFor="let item of lang_array">
              <a class="dropdown-item" (click)="setLang(item)" (touchstart)="setLang(item)">{{
                item.name
                }}</a>
            </li>
          </ul>
        </li>
      </ul>
      <button type="button" class="btn p-0 pe-1 me-4" id="menuLoginButton" data-bs-toggle="modal"
        data-bs-target="#example" (click)="getusericon(active_users)">
        <img src="../../assets/images/logout.svg" />
      </button>
    </nav>
  </div>
</nav>

<section id="pageOverall" class="features-icons bg-light text-center d-flex">
  <div *ngIf="control" [ngClass]="{ blur_button: order_viewdisable == true }">
    <div class="loading-event-pause w-100 d-flex" id="loading-event">
      <div class="loading-wrapper d-flex flex-column justify-content-center">
        <h2 class="font-mulish">{{ "HOME.BREAK" | translate }}</h2>
        <p class="mt-5 text-center">
          <i class="icon-loading-time d-inline-block"></i>
        </p>
        <p class="d-flex justify-content-center">
          <button class="btn btn-loading d-flex justify-content-start align-items-center" (click)="controlStatus()">
            <i class="icon-control-pause d-inline-block"></i>
            <span>{{ "HOME.RESUME" | translate }}</span>
          </button>
        </p>
      </div>
    </div>
  </div>
  <div class="container-fluid" [ngClass]="{
    order:
     OrderIntakeScreen == true
  }">
    <div id="userBar" class="row bg-gray position-relative">
      <div class="user-control p-0 d-flex justify-content-center icon-button-wrapper cstm-icon-button-wrapper">
        <button (click)="addOrder('s','','')"
          class="btn btn-light-blue w-100 p-0 d-flex justify-content-center align-items-center">
          <i class="icon-white-doc m-0"></i>
          <i class="icon-plus-small ms-1 me-0"></i>
        </button>
        <!-- <button class="btn btn-light-blue w-50 p-0 border-left d-flex justify-content-center align-items-center"
          (click)="controlStatus()" (touchend)="controlStatus()" [ngClass]="control ? 'pause' : 'play'">
          <i class="icon-control d-inline-block m-0"></i>
        </button> -->
      </div>
      <div class="username-button  user_add_dtl_wrapper" *ngFor="let item of active_users" (click)="!userControl?currentUser(item):''"
        [ngClass]="{
          active:
            item?.displayName == currentuser?.displayName && !user_screen,'textlgwider':active_users.length>4,'textsmwider':active_users.length <= 4
        }">
        <i class="icon-play-user" *ngIf="item?.icon == 'play'"></i>
        <i class="fa fa-pause-circle" *ngIf="item?.icon == 'pause'"></i>
        <span class="d-inline-block text-truncate text-start"
          [ngClass]="[active_users.length>4?'textsmaller':'textbigger']">
          {{ item?.displayName}}
        </span>
        <i [ngClass]="[active_users.length>4?'closesmaller':'closebigger']"
          class="icon-close-user ms-auto icon-close-user--btn" (click)="modalValues(item)" *ngIf="
            item?.displayName == currentuser?.displayName && !user_screen
          "></i>
      </div>
      <div class="addUser d-flex justify-content-center align-items-center user_add_icon_block" (click)="userList()"
        [ngClass]="{ active: user_screen }">
        <span><i class="icon-add-user m-0"></i></span>
      </div>
    </div>

    <div class="row mainScreen bg-white" *ngIf="status_screen">
      <div class="col-md-2 p-0 orderTabs custom-tab-responsive" [ngClass]="{ blur_button: order_viewdisable == true }">
        <div class="scrollable">
          <div class="row" [ngClass]="{ active: item?.focus == true }" *ngFor="let item of orders"
            (click)="!disabledOrder?focusButton(item):''" style="cursor: pointer;">
            <button class="status-item" [ngClass]="{
                green: item?.eventType == 'green',
                yellow: item?.eventType == 'yellow',
                red: item?.eventType == 'red',
                grey: item?.eventType == 'grey'
              }"></button>
            <p class="number m-0 text_ovrf_ellp">{{ item?.orderID }}</p>
            <span class="d-inline-block eventOrder text-truncate">{{ (item.languageKey) ? ("HOME."+item?.languageKey |
              translate): item?.eventDescription}}</span>
            <span class="d-inline-block eventOrder text-truncate">{{item.orderDescription}}</span>
          </div>


        </div>
        <div class="user-controller-scrole">
          <div *ngIf="+customizing?.showGeneralPauseButton === 1" class="user-control p-0 d-flex justify-content-center icon-button-wrapperr">
            <button class="bottombtn btn btn-light-blue w-100 p-0 border-left d-flex  align-items-center"
              [disabled]="this.active_order?.eventType != 'green' && !control && this.active_order?.eventDescription != 'Pauze'"
              (click)="controlStatus()" [ngClass]="control ? 'pause' : 'play'">
              <i class="desktopview icon-control d-inline-block m-0"></i> <span class=" fontnumber m-0 text-white">
                {{ "HOME.BREAK" | translate}}
              </span>
            </button>
          </div>
          <div *ngIf="customizing?.allow_ImprovementIdea"
            class="user-control  p-0 d-flex justify-content-center icon-button-wrapperr">

            <button class="bottombtn btn btn-light-blue w-100 p-0 border-left d-flex  align-items-center"
              (click)="setnavigation()">
              <i class=" desktopview fa fa-lightbulb-o"></i> <span class=" fontnumber m-0 text-white">
                {{
                "HOME.IDEABUTTON" | translate
                }}
              </span>
            </button>

          </div>
          <div *ngIf="(customizing?.showGeneralButtonForAttachments)"
            class="user-control  p-0 d-flex justify-content-center icon-button-wrapperr">

            <button class="bottombtn btn btn-light-blue w-100 p-0 border-left d-flex  align-items-center"
              (click)="attachmentApi()">
              <i class="desktopview fa fa-file-pdf-o"></i><span class=" fontnumber m-0 text-white">
                {{
                "HOME.INSTRUCTIONS_LABEL" | translate
                }}
              </span>
            </button>

          </div>
        </div>

      </div>
      <div class="col-md-10 wrapper-list-item d-flex flex-column">
        <div class="row row-center-section">
          <div class="screenMainSection" *ngIf="downtime_flag">
            <app-down-time #downTimeComponent></app-down-time>
          </div>
          <div class="screenMainSection" *ngIf="unplanned_flag">
            <app-unplanned-down-time #unplannedDownTime></app-unplanned-down-time>
          </div>
          <div class="screenMainSection" *ngIf="reports_flag">
            <app-reports></app-reports>
          </div>
          <div class="screenMainSection" *ngIf="reportsonly_flag">
            <app-reports [reportOnly]="true"></app-reports>
          </div>
          <div class="screenMainSection" *ngIf="workingon_flag">
            <app-working-on></app-working-on>
          </div>
          <div class="screenMainSection" *ngIf="project_flag">
            <app-project-list #projectlist></app-project-list>
          </div>
          <div class="screenMainSection" *ngIf="screen_status" [ngClass]="{ blur_button: order_viewdisable == true }">

            <div class="row ps-0 pe-0 actionButton action d-flex flex-row flex-nowrap" role="button" tabindex="-1"
              (click)="!isShow?set_flag('green', content):''; $event.stopPropagation();clearChecklistData()" data-allow-start="false"
              data-a="green" data-eventtype="green" data-level="1"
              [ngClass]="currentOrderColor==='green'? 'action-inactive':''">
              <button class="circle green mx-2 mx-md-4 mx-xl-2 mx-xxl-4"></button>
              <div class="col-md-9 ps-0 pe-0 col-12 my-auto text-start actionButton-content">
                <div class="row g-0">
                  <span class="button-text-large pl-2 ml-1 lh-1">{{
                    "HOME.GREENBUTTON" | translate
                    }}</span>
                </div>
                <div class="row g-0">
                  <div>
                    <span class="button-text-small pl-2 ml-1">{{
                      "HOME.GREEN_ACT" | translate
                      }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row ps-0 pe-0 actionButton d-flex flex-row flex-nowrap"
              (click)="!isShow?set_flag('yellow', 'content'):'';$event.preventDefault();"
              [ngClass]="currentOrderColor==='orange'? 'action-inactive':''"
              >
              <button class="circle yellow mx-2 mx-md-4 mx-xl-2 mx-xxl-4"></button>
              <div class="col-md-9 ps-0 pe-0 col-12 my-auto text-start actionButton-content">
                <div class="row g-0">
                  <span class="button-text-large pl-2 ml-1 lh-1">{{
                    "HOME.YELLOWBUTTON" | translate
                    }}</span>
                </div>
                <div class="row g-0">
                  <span class="button-text-small pl-2 ml-1" *ngIf="role == 'office'">{{
                    "HOME.YELLOW_ACT" | translate
                    }} {{
                    "HOME.OFFICETYPE" | translate
                    }}</span>
                  <span class="button-text-small pl-2 ml-1" *ngIf="role == 'shopfloor'">{{
                    "HOME.YELLOW_ACT" | translate
                    }} {{
                    "HOME.SHOPFLOORTYPE" | translate
                    }}</span>

                </div>
              </div>
            </div>
            <div class="row ps-0 pe-0 actionButton d-flex flex-row flex-nowrap" role="button" tabindex="-1"
              (click)="!isShow?set_flag('red', 'content'):'';$event.preventDefault();" data-a="RedStep1" data-level="1"
              [ngClass]="currentOrderColor==='red'? 'action-inactive':''"
              data-eventtype="red">
              <button class="circle red mx-2 mx-md-4 mx-xl-2 mx-xxl-4"></button>
              <div class="col-md-9 ps-0 pe-0 col-12 my-auto text-start actionButton-content">
                <div class="row g-0">
                  <div>
                    <span class="button-text-large pl-2 ml-1 lh-1">{{
                      "HOME.REDBUTTON" | translate
                      }}</span>
                  </div>
                </div>
                <div class="row g-0">
                  <span class="button-text-small pl-2 ml-1" *ngIf="role == 'office'">{{
                    "HOME.RED.RED_ACT" | translate
                    }} {{ "HOME.OFFICETYPE" | translate}}</span>
                  <span class="button-text-small pl-2 ml-1" *ngIf="role == 'shopfloor'">{{
                    "HOME.RED.RED_ACT" | translate
                    }} {{ "HOME.SHOPFLOORTYPE" | translate}}</span>
                </div>
              </div>
            </div>
            <div *ngIf=" +this.customizing?.allow_andon !== 0"
              class="row ps-0 pe-0 actionButton d-flex flex-row flex-nowrap" role="button" tabindex="-1"
              (click)="!isShow?set_flag('blue', 'content'):'';$event.preventDefault();">
              <button class="circle blue mx-2 mx-md-4 mx-xl-2 mx-xxl-4"></button>
              <div class="col-md-9 ps-0 pe-0 col-12 my-auto text-start actionButton-content">
                <div class="row g-0">
                  <span class="button-text-large pl-2 ml-1 lh-1">{{
                    "HOME.BLUEBUTTON" | translate
                    }}</span>
                </div>
                <div class="row g-0">
                  <span class="button-text-small pl-2 ml-1" *ngIf="role == 'office'">{{
                    "HOME.BLUE_ACT" | translate
                    }} {{"HOME.OFFICETYPE" | translate}}</span>
                  <span class="button-text-small pl-2 ml-1" *ngIf="role == 'shopfloor'">{{
                    "HOME.BLUE_ACT" | translate
                    }} {{"HOME.SHOPFLOORTYPE" | translate}}</span>
                </div>
              </div>
            </div>
            <div class="row ps-0 actionButton d-flex flex-row flex-nowrap" *ngIf="attachment_flag"
              [ngClass]="renderBlurClass()" (click)="openAttachment(); $event.preventDefault();">
              <div
                class="icon-doc-wrapper text-center mx-2 mx-md-4 mx-xl-2 mx-xxl-4 p-0 align-self-center d-flex align-items-center justify-content-center">
                <button class="icon-docs border-0 bg-white"></button>
              </div>
              <div class="col-md-9 ps-0 pe-0 col-12 my-auto text-start actionButton-content">
                <div class="row g-0">
                  <span class="button-text-large pl-2 lh-1">{{
                    "HOME.INSTRUCTDOCUMENT" | translate
                    }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="statusScreen">
            <!-- <div class="statusScreen--last-wrapper closeOrderBtn" style="margin-bottom:16px"
              (click)="set_flag('white', 'content');$event.preventDefault();"
              *ngIf="!reports_flag && !reportstatus_flag && !reportsonly_flag">
              <div class="d-flex flex-row flex-nowrap align-items-center">
                <div>{{ "HOME.WHITEBUTTON" | translate }}</div>
              </div>
            </div> -->
            <div class="status-box" *ngIf="reportstatus_flag" [ngClass]="{ blue: reportstatus_flag == true }">
              <div class="text-align my-auto blueStatusBar">
                <p class="font-mulish font-sm"
                  *ngIf="report_event?.languageKey != null && report_event?.languageKey != ''">
                  {{ "HOME."+report_event?.languageKey | translate }}
                </p>
                <p class="font-mulish font-sm"
                  *ngIf="report_event?.languageKey == null || report_event?.languageKey == ''">
                  {{ report_event?.eventDescription }}
                </p>
                <p>
                  <button type="button" class="btn btn-secondary" (click)="stop(report_event, 'blue',true,'')">
                    {{ "HOME.READYBUTTON" | translate }}
                  </button>
                </p>
              </div>
            </div>
            <ng-container *ngIf="timer_flag  && takt_today">
              <div class="status-box grey" [ngClass]="{
                green: active_order?.eventType == 'green',
                yellow: active_order?.eventType == 'yellow',
                red: active_order?.eventType == 'red',
                grey:
                  active_order == undefined ||
                  active_order?.eventType == 'grey' ||
                  active_order?.eventType == ''
              }" [ngStyle]="{'background-color': isShow ? currentOrderColor: ''}">
                <!-- <span class="activeColorTextDescription activeClassOrder  font-mulish font-sm">Working on the order</span> -->
                <span class="activeColorTextDescription activeClassOrder font-mulish font-sm"
                  *ngIf="active_order?.eventType == 'yellow' && !isShow">{{
                  "HOME.YELLOWBUTTON" | translate }} </span>
                <span class="activeColorTextDescription activeClassOrder font-mulish font-sm"
                  *ngIf="active_order?.eventType == 'red' && !isShow">{{
                  "HOME.REDBUTTON" | translate }}</span>
                <span class="activeColorTextDescription activeClassOrder   font-mulish font-sm" *ngIf="
              active_order != undefined &&
              (active_order?.eventType != 'grey' &&
                active_order?.eventType != 'green' &&
                active_order?.eventType != '') &&
              (active_order?.languageKey) != '' && (active_order?.languageKey) != null && !isShow
            ">{{ "HOME."+active_order?.languageKey | translate }} </span>
                <!-- <span class="activeColorTextDescription  activeClassOrder font-mulish font-sm" *ngIf="
            active_order != undefined &&
            (active_order?.eventType != 'grey' &&
              active_order?.eventType != 'green' &&
              active_order?.eventType != '') &&
            (active_order?.languageKey) == '' || (active_order?.languageKey) == null && !isShow
          ">{{ active_order?.eventDescription }} </span> -->
                <span class="activeColorTextDescription  activeClassOrder font-mulish font-sm" *ngIf="
                  active_order != undefined &&
                  active_order?.eventType == 'green' && !isShow
                ">{{ "HOME.GREENBUTTON" | translate }} </span>
                <div class="timmer-content-wrapper">
                  <div class="text-align timer running uiyioio" *ngIf="timer_flag && !isShow && takt_today">
                    <div class="uiyio">
                      <span class="iobg" [ngClass]="[positiveColor?'':'color-red']"> {{
                        "HOME.TAKT_TODAY" | translate }}</span>
                      <div class="sub-timer">
                        <!-- activeColorTextDescription -->
                        <span class=" uipl font-mulish font-sm">
                          {{ takt_today }}
                        </span>
                      </div>


                    </div>
                    <div class="border-bottom">

                    </div>

                  </div>
                  <div class="text-align timer running uiyioio " *ngIf="timer_flag && !isShow && takt_remaining">
                    <div class="uiyio">
                      <span class="iobg">

                        {{
                        "HOME.TAKT_REMAINING" | translate }}
                      </span>
                      <div class="sub-timer">
                        <!-- activeColorTextDescription -->
                        <span class=" uipl font-mulish font-sm">
                          {{ takt_remaining }} </span>
                      </div>

                    </div>

                    <div class="border-bottom">

                    </div>
                  </div>
                  <div class="text-align timer running " *ngIf="timer_flag && !isShow">
                    <span class="iobg">
                      {{
                      "HOME.PROCESSING" | translate }}

                    </span>
                    <div class="iobg">
                      {{
                      "HOME.WORKONTHEORDER" | translate }}
                    </div>
                    <div style="color:black">
                      {{ t }}
                    </div>
                  </div>
                  <div class="text-align timer running" *ngIf="isShow">
                    {{ordercounter}}
                  </div>

                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!(timer_flag && takt_today)">
              <div class="status-box grey" [ngClass]="{
                green: active_order?.eventType == 'green',
                yellow: active_order?.eventType == 'yellow',
                red: active_order?.eventType == 'red',
                grey:
                  active_order == undefined ||
                  active_order?.eventType == 'grey' ||
                  active_order?.eventType == ''
              }" [ngStyle]="{'background-color': isShow ? currentOrderColor: ''}">

                <div class="text-align timer running " *ngIf="timer_flag && !isShow">
                  {{ t }}
                </div>
                <div class="text-align timer running" *ngIf="isShow">
                  {{ordercounter}} </div>
                <span class="activeColorText font-mulish font-sm"
                  *ngIf="active_order?.eventType == 'yellow' && !isShow">{{
                  "HOME.YELLOWBUTTON" | translate }}</span>
                <span style="font-size: 10px;font-weight: normal;" class="activeColorText font-mulish font-sm"
                  *ngIf="(active_order?.eventType == 'yellow' && !isShow) && this.currentlang != 'Dutch'">Geplande
                  stilstand
                </span>
                <span class="activeColorText font-mulish font-sm" *ngIf="active_order?.eventType == 'red' && !isShow">{{
                  "HOME.REDBUTTON" | translate }}</span>
                <span style="font-size: 10px;font-weight: normal;" class="activeColorText font-mulish font-sm"
                  *ngIf="(active_order?.eventType == 'red' && !isShow) && this.currentlang != 'Dutch'">Ongeplande
                  stilstand</span>
                <span class="activeColorTextDescription font-mulish font-sm word_brk " *ngIf="
              active_order != undefined &&
              (active_order?.eventType != 'grey' &&
                active_order?.eventType != 'green' &&
                active_order?.eventType != '') &&
              (active_order?.languageKey) != '' && (active_order?.languageKey) != null && !isShow
            ">{{ "HOME."+active_order?.languageKey | translate }}</span>
                <span style="font-size: 10px;font-weight: normal;"
                  class="activeColorTextDescription font-mulish font-sm word_brk " *ngIf="
              active_order != undefined &&
              (active_order?.eventType != 'grey' &&
                active_order?.eventType != 'green' &&
                active_order?.eventType != '') &&
              (active_order?.languageKey) != '' && (active_order?.languageKey) != null && !isShow && this.currentlang != 'Dutch'
            ">{{ dutchTranslation.HOME[active_order?.languageKey] }} </span>
                <span class="activeColorTextDescription font-mulish font-sm word_brk " *ngIf="
            active_order != undefined &&
            (active_order?.eventType != 'grey' &&
              active_order?.eventType != 'green' &&
              active_order?.eventType != '') &&
            (active_order?.languageKey) == '' || (active_order?.languageKey) == null && !isShow
          ">{{ active_order?.eventDescription }}</span>
                <span class="activeColorTextDescription font-mulish font-sm" *ngIf="
                  active_order != undefined &&
                  active_order?.eventType == 'green' && !isShow
                ">{{ "HOME.GREENBUTTON" | translate }} </span>

                <span style="font-size: 10px;font-weight: normal;"
                  class="activeColorTextDescription font-mulish font-sm" *ngIf="
                  (active_order != undefined &&
                  active_order?.eventType == 'green' && !isShow) && this.currentlang != 'Dutch'
                ">Werken aan de order </span>
              </div>
            </ng-container>

            <div class="statusScreen--last position-relative" [ngClass]="[!disableClosebutton?'cursopr-pinter':'curson-none']" (click)="!disableClosebutton?delete_order():''">
              <div class="statusScreen--last-wrapper closeOrderBtn">
                <div class="d-flex flex-row flex-nowrap align-items-center">
                  <i class="icon-close"></i>
                  <div>{{ "HOME.CLOSEBUTTON" | translate }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mainScreen mt-5" style="display: flex;height: calc(100vh - 225px);
    overflow-y: scroll;" *ngIf="user_screen">
      <div class="col-md-1"></div>
      <div class="col-md-10 my-auto mt-5">
        <div class="modal-content userOverview">
          <div class="modal-header">
            <h5 class="modal-title" id="loginModalLabel">
              {{ "HOME.CHOOSEUSER" | translate }}
            </h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <a class="btn btn-primary btn-lg btn-block active justify-content-start"
                style="width: 320px;margin-left: inherit;" *ngFor="let items of available_users" role="button"
                aria-pressed="true" (click)="addUser(items)">{{ items.displayName }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
</section>

<div *ngIf="OrderIntakeScreen">
  <app-orderintake-flow></app-orderintake-flow>
</div>

<br />



<!-- Modal -->
<div class="modal fade" id="example" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="example">
          {{ "HOME.LOGOUT" | translate }}
        </h2>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h2>{{ "HOME.LOGOUTCONTENT" | translate }}</h2>
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button type="button" class="btn btn-primary logout" data-bs-dismiss="modal" (click)="logout()">
          {{ "HOME.CONFIRMBUTTON" | translate }}
        </button>
        <button type="button" class="btn btn-success reset logout" data-bs-dismiss="modal">
          {{ "HOME.CANCELBUTTON" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p style="display: none">{{ modal_item }}</p>
        <h2>Are you sure you want to sign out?</h2>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="removeUser(modal_item)">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>


<app-checklist-modal #instructions [requiresValidationOnSubmit]="requiresValidationOnSubmit" [attachments]="attachments" [checkListItems]="checkListItems"
  [checkListFormGroup]="checkListFormGroup" [checkListItems1]="checkListItems1"
  [checkListFormGroup1]="checkListFormGroup1" [check_List]="check_List" [checklistFormList]="checklistFormList"
  [goodsRecipt]="goodsReciptFlag" [orderID]="active_order?.orderID"></app-checklist-modal>

<ng-template #logoutt let-modal>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">
      {{ "HOME.USERSIGNOUTTOPIC" | translate }}
    </h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p style="display: none">{{ modal_item }}</p>
    <h2>{{ "HOME.USERSIGNOUT" | translate }}</h2>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary logout" (click)="removeUser(modal_item)">
      {{ "HOME.CONFIRMBUTTON" | translate }}
    </button>
    <button type="button" class="btn btn-success reset logout" (click)="modal.close('Save click')">
      {{ "HOME.CANCELBUTTON" | translate }}
    </button>

  </div>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">
      {{ "HOME.ACTIVITYTITTLE" | translate }}
    </h2>
    <button type="button" class="btn-close" aria-label="Close"
      (click)="modal.dismiss('Cross click'); clearGreenData()"></button>
  </div>
  <div class="modal-body">
    <p *ngIf="role == 'office'">
      {{ "HOME.ACTIVITYCONTENT_OFFICE" | translate }}
    </p>
    <p *ngIf="role == 'office'">
      {{ "HOME.ACTIVITY_QUES_OFFICE" | translate }} {{ green_eventdata?.orderID }}
      {{ "HOME.ON_WORKSTATION" | translate }} {{ name }} doen?
    </p>
    <p *ngIf="role != 'office'">
      {{ "HOME.ACTIVITYCONTENT" | translate }}
    </p>
    <p *ngIf="role != 'office'">
      {{ "HOME.ACTIVITY_QUES" | translate }} {{ green_eventdata?.orderID }}
      {{ "HOME.ON_WORKSTATION" | translate }} {{ name }} ?
    </p>
    <form [formGroup]="activityForm" novalidate>
      <div class="form-check">
        <input class="form-check-input" type="radio" (click)="onsetOrderid()" name="event" formControlName="event"
          value="close" id="flexRadioDefault1" />
        <label class="form-check-label" for="flexRadioDefault1">
          {{ "HOME.CLOSEBUTTON" | translate }}
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="event" formControlName="event" value="yellow"
          id="flexRadioDefault2" />
        <label class="form-check-label" for="flexRadioDefault2">
          {{ "HOME.PUT_YELLOW" | translate }}
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="event" formControlName="event" value="red"
          id="flexRadioDefault3" />
        <label class="form-check-label" for="flexRadioDefault3">
          {{ "HOME.PUT_RED" | translate }}
        </label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onSubmit_event()">
      {{ "HOME.CONFIRMBUTTON" | translate }}
    </button>
    <button type="button" class="btn btn-success reset" (click)="modal.close('Save click'); clearGreenData()">
      {{ "HOME.CANCELBUTTON" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #attachment let-modal>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">Document</h2>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      Sluiten
    </button>
  </div>
  <div class="modal-body">
    <div class="row h-100">
      <div class="col-lg-2" style="border-right: 1px solid #eee">
        <span *ngFor="let itemss of attachments">
          <button class="width-150px btn active pbm" aria-pressed="true" (click)="open_modal(itemss)">
            {{ itemss.document_type }}
          </button>
          <br />
        </span>
      </div>
      <div class="col-lg-10 pdf-container">
        <iframe [src]="src" class="iframe" frameborder="0"></iframe>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #popupformmodal let-modal>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title"> {{'HOME.ADDITIONALINFOTITTLE' | translate}} </h2>
    <button type="button" *ngIf="SelectedCategory?.preventSkipExtraLevel != '1'" class="btn-close" aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="popupform" (keyup.enter)="submitdropdown()" novalidate>
      <div *ngFor="let a of placeholder;let i=index">
        <div class="mb-3">
          <div class="form-group">
            <input type="text" class="form-control" id="validate" [formControlName]="a" placeholder="{{a}}">
          </div>
        </div>
      </div>
      <div class="mb-3" *ngIf="dropdown_values?.length > 0">
        <select class="form-select form-select-lg" formControlName="mail" [(ngModel)]="dropdown_values[0]"
          aria-label=".form-select-sm example">
          <option *ngFor="let item of dropdown_values" value={{item}}>{{item}}</option>
        </select>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="submitdropdown()">{{'HOME.CONFIRMBUTTON' |
      translate}}</button>
    <button type="button" *ngIf="SelectedCategory?.preventSkipExtraLevel != '1'" class="btn btn-success reset"
      (click)="modal.close('Save click')">{{'HOME.CANCELBUTTON' | translate}}</button>
  </div>
</ng-template>
<ng-template #attachemets let-modal>
  <div class="modal-header">
    <h2 class="modal-title" style="color: #24597A;" id="modal-basic-title">Attachments</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row" style="height: 100%;">
      <div class="col-md-3">
        <ul class="ul-list">
          <li>
            <ng-container>
          <li *ngFor="let items of attachmentsData"><a [ngClass]="{
              bold: '' == items.document_type
            }" (click)="setPdfDocuments(items.document_type,items)">
              {{items.document_type}}</a></li>
          </ng-container>
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <ng-container>

          <iframe *ngIf="srcc" [src]="srcc" class="iframe" frameborder="0" width="100%" height="100%"></iframe>

        </ng-container>
      </div>

    </div>


  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success reset" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>
<ngx-ui-loader></ngx-ui-loader>
<div class="attention"></div>